.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.header {
  display: flex;
  z-index: 9999;
}

.content {
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
}

