.menu {
  flex-grow: 1;
}

.menu-mobile {
  display: flex;
  justify-content: space-around;
  padding-top: 20px !important;
}

.language-button {
  border-bottom-color: transparent !important;
}

.language-item {
  display: flex !important;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
}

.right-button {
  float: right;
  top: 24px !important;
}

